// - VARIABLES
$sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$small: 18px;
$turtleGreen: rgb(81, 209, 81);

// - STYLES
body {
  font-family: $sans;
  color: #333;
  line-height: 25px;
}

p, li {
  line-height: 35px;
  font-size: $small;
}

.container {
  width: 90%;
  max-width: 600px;
  padding-top: 50px;
  margin: auto;
}

i {
  padding-right: 10px;
}

a, h1:first-of-type {
  color: $turtleGreen;
}

footer {
  margin-bottom: 80px;
}

.linkBox {
  margin-bottom: 10px;
}
